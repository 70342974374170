<template>
  <!-- <b-card-code title="Column Search Table"> -->
    <div class="card mb-5 mb-xl-10">
      <div class="card-body">
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="pengaduan"
      :rtl="direction"
      :line-numbers="true"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <!-- <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div> -->

        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-b-modal.compose-mail @click="getpertanyaan(props.row.nama_cluster); getdata(props.row.id);">
                <!-- <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                /> -->
                <span>Jawab</span>
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.modal-kembalikan @click="getpertanyaan(props.row.nama_cluster); getcatatan(props.row.id); getdata(props.row.id);">
                <!-- <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                /> -->
                <span>Kembalikan</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    
    <b-modal
      id="compose-mail"
      ref="my-modal"
      title="Jawab"
      scrollable
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
    
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="Pertanyaan"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
        <div style="height: 200px; overflow-y: auto;">
          <ul v-for="pertanyaan in pertanyaan" :key="pertanyaan">
            <li>
              {{pertanyaan.pertanyaan}}
            </li>
          </ul>
        </div>
        </b-form-group>
        <b-form-group
          :state="jawabanState"
          label="Jawaban"
          label-for="name-input"
          invalid-feedback="Jawaban is required"
        >
          <div class="message-editor border-bottom-1">
            <vue-editor v-model="datapengaduan.jawaban" :editorToolbar="customToolbar" />
          </div>
        </b-form-group>

      <b-form-group
          :state="nameState"
          label="Attachment"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-file
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          v-model="datapengaduan.filename"
        />
      </b-form-group>

      </form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleOk"
          >
            Kirim
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-kembalikan"
      ref="my-modal"
      title="Kembalikan"
      scrollable
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk2"
    >
      <form
        ref="form"
        @submit.stop.prevent="kembalikan"
      >
        <b-form-group
          label="Pertanyaan"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <ul v-for="pertanyaan in pertanyaan" :key="pertanyaan">
            <li>
              {{pertanyaan.pertanyaan}}
            </li>
          </ul>
        </b-form-group>
        <b-form-group
          :state="alasanState"
          label="Alasan dikembalikan"
          label-for="name-input"
          invalid-feedback="Alasan is required"
        >
          <b-form-textarea
            id="textarea-auto-height"
            placeholder="Alasan"
            rows="3"
            max-rows="8"
            v-model="catatan"
            :state = alasanState
            required
          />
        </b-form-group>
      </form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleOk2"
          >
            Kirim
          </b-button>
        </div>
      </template>
    </b-modal>
    </div>
    </div>
  <!-- </b-card-code> -->
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BFormTextarea,BFormFile,BButton,BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeColumnSearch } from './code'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import { VueEditor } from "vue2-editor";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    quillEditor,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    BFormFile,
    BButton,
    BFormDatepicker,
    Ripple,
    VueEditor
  },
  setup(){
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Jawaban',
    }
    return {
      editorOption
    }
  },
  data() {
    return {
      name: '',
      jawabanState: null,
      submittedNames: [],
      pageLength: 10,
      dir: false,
      codeColumnSearch,
      url: localStorage.getItem('baseapi'),
      pengguna: JSON.parse(localStorage.getItem('userData')),
      customToolbar: [
        ["bold", "italic", "underline"],
      ],
      columns: [
        {
          label: 'Nama Cluster',
          field: 'nama_cluster',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Nama Cluster',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      pengaduan:[],
      pertanyaan:[],
      datapengaduan:[],
      datacatatan:[],
      catatan:''
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        "Answered by Expert"      : 'light-primary',
        "Resolved" : 'light-success',
        "Returned by Expert"     : 'light-danger',
        "Answered"      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted(){
    this.loaddata()
  },
  created() {
   
  },
  methods: {
    loaddata(){
      if (this.pengguna.hak_akses == 'expert') {
        var api ='cluster?filter=expert,=,'+this.pengguna.nama;
      } else {
        var api ='cluster';
      }
      axios.get(this.url+api, {
      headers: {
          "Content-type": "application/json",
          "xth": this.pengguna.token,
          }
      })
      .then(response => {
        // const { users, total } = response.data.data
        this.pengaduan = response.data.data
      })
      .catch( error => {
        if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
          this.$swal({
            title: error.response.data,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
            }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem('userData')
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }
          })
        } else {
         this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
      })
    },
    getpertanyaan(pertanyaan){
      axios.get(this.url+'pertanyaan_cluster?filter=cluster,=,'+pertanyaan, {
      headers: {
          "Content-type": "application/json",
          "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.pertanyaan = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    getcatatan(id){
      axios.get(this.url+'catatan_cluster?filter=cluster_id,=,'+id, {
      headers: {
          "Content-type": "application/json",
          "xth": this.pengguna.token,
          }
      })
      .then(response => {
        console.log(response.data)
        this.datacatatan = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    getdata(id){
      axios.get(this.url+'cluster/'+id, {
      headers: {
          "Content-type": "application/json",
          "xth": this.pengguna.token,
          }
      })
      .then(response => {
        console.log('jawaban')
        console.log(response.data.data)
        this.datapengaduan = response.data.data
        // const { users, total } = response.data.data
        // this.pengaduan = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.jawabanState = valid
      this.alasanState = valid
      return valid
    },
    resetModal() {
      this.datapengaduan = []
      this.datacatatan = []
      this.jawabanState = null
      this.alasanState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleOk2(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.kembalikan()
    },
    handleSubmit() {
      let formData = new FormData();
                formData.append('jawaban', this.datapengaduan.jawaban);
                formData.append('attachment', this.datapengaduan.filename);
            if (!this.checkFormValidity()) {
              return
            }
                // tampak_depan
          axios
            .post(this.url+'cluster/'+this.datapengaduan.id, formData,{ 
                // _method: 'patch',
              headers: {
                        'Content-Type': 'multipart/form-data',
                        "xth": this.pengguna.token,
                    }
            })
            .then(response => {
            
              this.loaddata()
              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Berhasil`,
                    },
                  })
            
              console.log(response.data)
              
              return response
              
            })
            .catch(error=>{
            console.log(error)
            
              return error
          })

      // Hide the modal manually
      this.$nextTick(() => {
          this.$bvModal.hide('compose-mail')
      })
    },
    kembalikan(){
      const catatan = this.catatan;
      axios.post(this.url+'cluster/'+this.datapengaduan.id, {
        catatan
      },{
        headers: {
          "Content-type": "application/json",
          "xth": this.pengguna.token,
            }
      })
      .then(response => {
        this.loaddata()
        this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Berhasil`,
              },
            })
            
          console.log(response.data)
      })
      .catch(error => {
        return error
      })
      this.$nextTick(() => {
          this.$bvModal.hide('modal-kembalikan')
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
<style lang="scss" scoped>
form ::v-deep {
  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>